import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"

import { StaticImage } from "gatsby-plugin-image"
import { MdKeyboardArrowRight } from "react-icons/Md"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/global.css"

const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="スキル管理ツール" />

    <section className="fv">
      <div className="fv_left">
        <h2>
          社内の経歴・スキルが
          <br />
          全て一つの場所で管理できる
        </h2>
        <h3>クラウド型スキルシート管理サービス</h3>
        <p>
          Skill-Repo(スキルレポ)は実績や経歴が重要となるエンジニアの経歴書を
          スマートに管理でき、企業の人事管理や営業の効率化に役立つ
          クラウド型スキルシート管理サービスです。
        </p>
        <div className="link_box">
          {" "}
          <Link
            className="fv_link fv_sign_up"
            to="https://skill-repo.jp/register"
            target="_blank"
            rel="noopener noreferrer"
          >
            Skill-Repoに新規登録
            <MdKeyboardArrowRight className="faAngleRight" />
          </Link>
        </div>
      </div>
      <div className="fv_right">
        <StaticImage className="fvimg" src="../images/fvimg.png" alt="PC" />
      </div>
    </section>
    <section className="topics" id="topics">
      <div className="ttlbox">
        <h2 className="ttl">お知らせ</h2>
        <p className="ttlsub">Topics</p>
      </div>
      <div className="news">
        <ul>
          {data.allStrapiArticle.nodes.map(document => (
            <li key={document.id}>
              <p>{document.day}</p>
              <p>{document.title}</p>
            </li>
          ))}
        </ul>
      </div>
    </section>

    <section className="feature" id="features">
      <StaticImage
        className="top"
        src="../images/feature01.png"
        alt="キー入力"
      />
      <div className="middle">
        <h2 className="ttl">スキルレポとは？</h2>
        <p className="ttlsub">What Is SkillRepository?</p>
        <p className="txt">
          社員の経歴書管理、スキル管理をはじめ、経歴書のメール送信・ダウンロードなどを提供するクラウド型スキルシート管理サービスです。
          <br />
          アカウントを登録していただくだけで即日始めることができます。
          1社につき５名まで無料でお試しいただけますので、お気軽にお試しください。
        </p>

        {/* <h3 className="ttls"> Point.</h3> */}
        <ul>
          <li>
            <span>- Point.01 -</span>
            <p>初期費用なし、月額定額でご利用いただけます。</p>
          </li>
          <li>
            <span>- Point.02 -</span>
            <p>SES営業支援機能を取り揃え、営業事務の効率が可能です。</p>
          </li>
          <li>
            <span>- Point.03 -</span>
            <p>
              アカウントを登録するだけ。簡単でスピーディーな導入が可能です。
            </p>
          </li>
        </ul>
      </div>
      <StaticImage
        className="bottom"
        src="../images/feature02.png"
        alt="会議"
      />
    </section>
    <section className="function" id="functions">
      <div className="ttlbox">
        <h2 className="ttl">スキルレポでできることとは？</h2>
        <p className="ttlsub">What you can do？</p>
      </div>
      <div className="function01">
        <StaticImage
          className="function01_img"
          src="../images/function01.png"
          alt="pc"
        />
        <div className="txtbox">
          <span>- function.01 -</span>
          <h3>フォーマットなどを意識せずにスキルシートの作成</h3>
          <p>
            スキルシートの作成はフォームに入力するだけ。入力時にレイアウトや印刷時のことを考えずに作成することができます。また、常にスキルシートを最新状態にしていただくために、指定期間ごとに更新リマインドメールで通知することも可能です。
          </p>
        </div>
      </div>
      <div className="function02">
        <StaticImage
          className="function02_img"
          src="../images/function02.png"
          alt="pc"
        />
        <div className="txtbox">
          <span>- function.02 -</span>
          <h3>SES営業の効率化を図る</h3>
          <p>
            SES営業で必要となる経歴書の送付もSkill-Repo上から行うことができます。送付の際に氏名をマスクするか選択することが可能で、面倒な編集作業などは必要ありません。
          </p>
          <p>
            また社内だけでなく、社外パートナー技術の方も招待することができ、統一されたフォーマットで経歴書を生成します。
            これによりフォーマットの修正や、営業担当者での面倒な修正作業を削減し、効率化に貢献します。
          </p>
        </div>
      </div>
      <div className="function03">
        <StaticImage
          className="function03_img"
          src="../images/function03.png"
          alt="pc"
        />
        <div className="txtbox">
          <span>- function.03 -</span>
          <h3>社員の所有スキル・資格の可視化</h3>
          <p>
            スキルや資格は会社ごとにマスタの設定が可能です。会社ごとの業務内容などにより自由にカスタマイズすることができます。
          </p>
          <p>
            スキルや資格の情報は経歴書作成と同時に登録、管理が可能。ダッシュボード画面から、スキルごとに要員数を確認することができます。また、スキルごとに要員の検索も可能。スキルマッチする要員をすぐに探すことができます。
          </p>
        </div>
      </div>
    </section>

    <section className="casestudy" id="casestudys">
      <div className="ttlbox">
        <h2 className="ttl">活用事例</h2>
        <p className="ttlsub">case study</p>
      </div>
      <div className="container">
        <div className="casestudy01">
          <div className="txtbox">
            <span>- case.01 -</span>
            <h3>人事管理</h3>
            <p>
              従業員一人一人がこれまで行ってきた業務や現在のスキル情報が一覧で確認できるため、時間のかかる人事評価などを効率的に実施することができます。適切な人材の把握とスムーズな人的運用が実現可能となります。
            </p>
          </div>
          <div className="iconbox">
            <div className="icon">
              <StaticImage
                className="iconbox_img"
                src="../images/icon01.png"
                alt="icon"
              />
              <p>社員の資格・スキルの一元管理</p>
            </div>
            <div className="icon">
              <StaticImage
                className="iconbox_img"
                src="../images/icon02.png"
                alt="icon"
              />
              <p>スキルごとの絞り込み</p>
            </div>
            <div className="icon">
              <StaticImage
                className="iconbox_img"
                src="../images/icon03.png"
                alt="icon"
              />
              <p>スキルの可視化</p>
            </div>
          </div>
        </div>
        <div className="casestudy02">
          <div className="txtbox">
            <span>- case.02 -</span>
            <h3>営業</h3>
            <p>
              従来のテキストベースの提案メールではエンジニアのスキルや魅力が伝わりにくいことも…。Skill-Repoなら、共有ファイルの送信で簡単で伝わりやすい提案が可能です。また、データはPDFでの出力も可能です。
            </p>
          </div>
          <div className="iconbox">
            <div className="icon">
              <StaticImage
                className="iconbox_img"
                src="../images/icon04.png"
                alt="icon"
              />
              <p>メール機能</p>
            </div>
            <div className="icon">
              <StaticImage
                className="iconbox_img"
                src="../images/icon05.png"
                alt="icon"
              />
              <p>期限付きのファイル共有</p>
            </div>
            <div className="icon">
              <StaticImage
                className="iconbox_img"
                src="../images/icon06.png"
                alt="icon"
              />
              <p>PDF出力</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="question" id="questions">
      <div className="ttlbox">
        <h2 className="ttl">よくある質問</h2>
        <p className="ttlsub">question</p>
      </div>
      <ul>
        <li>
          <h3>Q 月額料金はいくらからになりますか？</h3>
          <p>
            料金は1人あたり500円（税抜）です。（※1社あたり5人までは無料になります。)
          </p>
        </li>
        <li>
          <h3>Q どのくらいの規模の法人まで対応可能ですか？</h3>
          <p>従業員数1名から1,000名程度まで、柔軟に対応可能です。</p>
        </li>
        <li>
          <h3>Q 最低契約期間はありますか？</h3>
          <p>最低契約期間は1ヶ月間となります。</p>
        </li>
        <li>
          <h3>Q 導入前にシステムを確認することはできますか？</h3>
          <p>
            従業員数5名まで無料でご利用いただくことが可能です。ご希望の方は{" "}
            <Link
              className="link"
              to="https://skill-repo.jp/register"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </Link>
            からお申し込みください。
          </p>
        </li>
      </ul>
    </section>
  </Layout>
)

export default IndexPage

export const query = graphql`
  {
    allStrapiArticle {
      nodes {
        day(formatString: "YYYY/MM/DD")
        createdAt(formatString: "YYYY/MM/DD")
        title
      }
    }
  }
`
